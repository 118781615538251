<template>
  <div class="camp-manager">
    <div class="camp-search">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="营地名称：">
          <el-input v-model="campName" placeholder="请输入营地名称"></el-input>
        </el-form-item>
        <el-form-item label="营地位置：">
          <el-cascader
              size="large"
              :options="options"
              v-model="value"
              clearable
              style="width: 400px;"
              :props="cascaderProps"
              @change="handleChange">
          </el-cascader>
        </el-form-item>
        <el-form-item label="创建时间：">
          <el-date-picker
              v-model="time"
              type="date"
              clearable
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left: 80px">
          <el-button type="primary" style="background: rgb(0,150,136);" @click="handleSearch">查询</el-button>
          <el-button type="primary" style="background: rgb(0,150,136);" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="card-main">
      <!--<el-button type="primary" @click="add">新增营地</el-button>-->
      <el-button type="primary" style="background: rgb(0,150,136);" @click="addShow = true">新增营地</el-button>
      <div class="card-list">
        <div class="item" v-for="item in cardList" :key="item.pid">
          <card-item :item-data="item" @resetPswd="resetPswd" @remove="remove" @up="up" @detail="getDetail"></card-item>
        </div>
      </div>
      <!--分页-->
      <!--<div style="text-align: right;">-->
      <!--  <el-pagination-->
      <!--      background-->
      <!--      layout="prev, pager, next"-->
      <!--      :total="TotalRecordCount"-->
      <!--      @next-click="nextlist"-->
      <!--      @prev-click="prevlist"-->
      <!--  >-->
      <!--  </el-pagination>-->
      <!--</div>-->
    </div>
    <!--新增营地-->
    <el-dialog title="新增营地" top="0" :visible.sync="addShow" width="50%" :close-on-click-modal="false" :modal="false" :before-close="addClose">
      <el-form label-width="100px">
        <el-form-item label="营地名称" :rules="{required: true, message: '请输入营地名称', trigger: 'blur'}">
          <el-input v-model="addName" placeholder="请输入营地名称" style="width: 50%;"></el-input>
        </el-form-item>
        <el-form-item label="营地类型" :rules="{required: true, message: '请选择营地类型', trigger: 'blur'}">
          <el-select v-model="addCampType" placeholder="请选择营地类型">
            <el-option
                v-for="item in addCampTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址" :rules="{ required: true, message: '请选择地址', trigger: 'blur' }">
          <el-cascader
              v-model="AddcityCode"
              :options="AddcityCodeList"
              :props="addprops"
              @change="addcityChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" :rules="{ required: true, message: '请选择详细地址', trigger: 'blur' }">
          <el-input v-model="addRess" type="textarea" placeholder="请选择地址" style="width: 50%;"></el-input>
          <el-button style="margin-left: 10px;" type="primary" @click="seachMap">搜索地址</el-button>
          <Map ref="map" @address="getAddress" @la="getJWd"></Map>
        </el-form-item>
        <el-form-item label="联系电话">
          <div>
            <div v-for="(item, index) in addPhoneList" :key="index" style="margin-bottom: 10px;">
              <el-input v-model="item.value" placeholder="请输入联系电话" style="width: 200px;"></el-input>
              <span v-if="index > 0" style="color: blue;cursor:pointer;margin-left: 10px;" @click="removePhone(index)">删除</span>
            </div>
            <div style="color: blue;cursor:pointer;">
              <span @click="addPhone">添加联系电话</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="管理员账号" :rules="{ required: true, message: '请输入管理员账号', trigger: 'blur' }">
          <el-input v-model="addUser" placeholder="请输入管理员账号" style="width: 50%;"></el-input>
          <span style="margin-left: 10px;">初始默认密码: 888888</span>
        </el-form-item>
        <el-form-item>
          <el-button @click="addClose">取消</el-button>
          <el-button type="primary" @click="addCamp">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--营地详情-->
    <el-dialog title="营地详情" :visible.sync="detailShow" :close-on-click-modal="true" custom-class="detail_box">
      <div class="detail_box_title" slot="title">
        <span>营地详情</span>
      </div>
      <el-form label-width="100px">
        <el-form-item label="营地名称">
          <span>{{ detailInfo.name }}</span>
        </el-form-item>
        <el-form-item label="营地类型">
          <span v-if="detailInfo.type === '0'">露营</span>
          <span v-if="detailInfo.type === '1'">研学</span>
          <span v-if="detailInfo.type === '2'">民宿</span>
          <span v-if="detailInfo.type === '3'">滑雪</span>
          <span v-if="detailInfo.type === '4'">垂钓</span>
        </el-form-item>
        <el-form-item label="省">
          {{ detailInfo.provinceName }}
        </el-form-item>
        <el-form-item label="市">
          {{ detailInfo.cityName }}
        </el-form-item>
        <el-form-item label="详细地址">
          {{ detailInfo.address }}
        </el-form-item>
        <el-form-item label="联系电话">
          <span v-for="(item, index) in detailInfo.phone" :key="index">
            {{ item }}
          </span>
        </el-form-item>
        <el-form-item label="管理员账号">
          {{ detailInfo.userAccount }} <span>初始默认密码: 888888</span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import CardItem from './components/card'
import Map from './components/map'
/** no-unuser-vars CodeToText */
// import { regionData } from 'element-china-area-data'
import { fetchList, removeItem, campsiteUp, resetPassword, areaList, campsiteDeatil, campsiteAdd } from "@/request/campManager";

export default {
  components: {
    CardItem,Map
  },
  data() {
    return {
      value: [],
      options: [],
      calenderVal: '',
      time: '',
      campName: '',
      cardList: [],
      pageIndex: 1,
      cityCode: '',
      cascaderProps: {
        checkStrictly: true,
        lazy: true,
        value: 'code',
        label: 'name',
        async lazyLoad(node,resolve){
          try {
            const { data } = node;
            const { code } = data
            console.log(code)
            const parms = new FormData()
            parms.append('parentid', code)
            const res = await areaList(parms)
            if (res.ResultCode === 0) {
              resolve(res.Data)
            }
          }catch (e) {
            console.log(e, '错误')
          }
        }
      },
      addShow: false,
      detailShow: false,
      detailInfo: {},
      MapShow: false,
      addRess: '',
      addCampType: '',
      addCampTypeList: [
        {
          value: '0',
          label: '露营'
        },
        {
          value: '1',
          label: '研学'
        },
        {
          value: '2',
          label: '民宿'
        },
        {
          value: '3',
          label: '滑雪'
        },
        {
          value: '4',
          label: '垂钓'
        }
      ],
      addPhoneList: [
        {
          value: ''
        }
      ],
      AddcityCode: '',
      AddcityCodeList: [],
      addprops: {
        checkStrictly: false,
        lazy: true,
        value: 'code',
        label: 'name',
        async lazyLoad(node,resolve){
          console.log(node.level);
          try {
            const { data, level } = node;
            const { code } = data
            console.log(code)
            if (level < 2) {
              const parms = new FormData()
              parms.append('parentid', code)
              const res = await areaList(parms)
              if (res.ResultCode === 0) {
                resolve(res.Data)
              }
            } else {
              resolve()
            }
          }catch (e) {
            console.log(e, '错误')
          }
        }
      },
      addName: '',
      addUser: '',
      jwd: [],
      addRules: {
        addName: {required:true,message: '请输入活动名称',trigger: 'blur'}
      },
      TotalRecordCount: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.GetArea()
      this.getList()
      console.log(this.options)
    })
  },
  methods: {
    async getList() {
      let params = {
        limit: '10',
        page: this.pageIndex,
        name: this.campName,
        cityCode: this.cityCode,
        time: this.time
      }
      const res = await fetchList(params)
      if (res.ResultCode == 0) {
        this.TotalRecordCount = res.Data.TotalRecordCount
        for(let i=0;i<res.Data.Items.length;i++){
          try {
            res.Data.Items[i].phone = JSON.parse(res.Data.Items[i].phone)
            res.Data.Items[i].homePage = JSON.parse(res.Data.Items[i].homePage)[0]
          } catch (e) {
            console.log(e)
          }
        }
        this.cardList = res.Data.Items
      }
    },
    async GetArea() {
      // const parms = {
      //   level: 1
      // }
      const parms = new FormData()
      parms.append('level', 1)
      const res = await areaList(parms)
      if (res.ResultCode === 0) {
        console.log(res)
        this.options = res.Data
        this.AddcityCodeList = res.Data
      }
    },
    handleChange(e) {
      console.log(e)
      this.cityCode = e[e.length-1]
      console.log(this.cityCode)
    },
    nextlist(e) {
      console.log(e, '下一页')
    },
    prevlist(e) {
      console.log(e, '上一页')
    },
    handleSearch() {
      this.pageIndex = 1
      this.getList()
    },
    handleReset() {
      this.value = []
      this.time = ''
      this.cityCode = ''
      this.pageIndex = 1
      this.campName = ''
      this.calenderVal = ''
      this.getList()
    },
    addcityChange(e) {
      console.log(e)
    },
    // 重置密码
    resetPswd(pid) {
      // console.log(pid, '重置密码')
      this.$alert('您确定要重置密码吗？确定重置后密码将恢复为初始密码888888','提示',{
        confirmButtonText: '确认',
        showCancelButton: true,
        cancelButtonText: '取消',
        callback: async (confirm) => {
          // console.log(confirm,'点了确认还是取消')
          if (confirm === 'confirm') {
            const res = await resetPassword(pid)
            if (res.ResultCode === 0) {
              this.$message({
                type: 'success',
                message: '重置密码成功'
              })
            }
          }
        }
      })
    },
    // 删除
    remove(pid) {
      // console.log(pid)
      this.$alert('您确定删除此平台吗?','提示',{
        confirmButtonText: '确认',
        showCancelButton: true,
        cancelButtonText: '取消',
        callback: async (confirm) => {
          if (confirm === 'confirm') {
            const res = await removeItem(pid)
            if (res.ResultCode === 0) {
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.getList()
            }
          }
        }
      })
    },
    async up(pid,state) {
      const parms = new FormData()
      parms.append('isshow', state)
      parms.append('pid', pid)
      const res = await campsiteUp(parms)
      if (res.ResultCode === 0) {
        this.getList()
      }
    },
    add() {
      // window.open('http://testapi.yunyouzhihui.com/start/index.html#/yy/lb_campsite/add', '_black')
    },
    addClose() {
      this.jwd = []
      this.addPhoneList = [
        {
          value: ''
        }
      ]
      this.$refs.map.jwd = ''
      this.$refs.map.address = ''
      this.AddcityCode = ''
      this.addUser = ''
      this.addRess = ''
      this.addName = ''
      this.addCampType = ''
      this.$refs.map.map.setCenter([120.14,30.25])
      this.$refs.map.removeClir()
      this.$refs.map.removeMarker()
      this.addShow = false
    },
    // 获取营地详情
    async getDetail(pid) {
      const res = await campsiteDeatil(pid)
      console.log(res, '营地详情')
      if (res.ResultCode === 0) {
        try {
          res.Data.phone = JSON.parse(res.Data.phone)
        }catch (e) {
          console.log(e)
        }
        this.detailShow = true
        this.detailInfo = res.Data
      }
    },
    // 获取详细地址
    getAddress(val) {
      this.addRess = val
    },
    // 添加电话
    addPhone() {
      let parms = {
        value: ''
      }
      this.addPhoneList.push(parms)
    },
    // 电话删除
    removePhone(index) {
      this.addPhoneList.splice(index, 1)
    },
    // 获取经纬度
    getJWd(val) {
      console.log(val, '经纬度')
      this.jwd = val
    },
    // 新增营地
    async addCamp() {
      if (this.addName === '') {
        this.$message.error('请输入营地名称')
      } else if (this.addCampType === '') {
        this.$message.error('请选择营地类型')
      } else if (this.AddcityCode === '') {
        this.$message.error('请选择营地地址')
      } else if (this.addRess === '') {
        this.$message.error('请选择营地详细地址')
      } else if (this.addUser === '') {
        this.$message.error('请输入管理员账号')
      } else {
        let phoneArr = []
        this.addPhoneList.forEach(item => {
          phoneArr.push(item.value)
        })
        console.log(phoneArr, '电话');
        let flag = phoneArr.some(item => item !== '')
        console.log(flag)
        if (!flag) {
          this.$message.error('联系方式不能为空')
        } else {
          // 调起接口
          console.log(phoneArr)
          let longitude = `${this.jwd[0]}`
          let latitude = `${this.jwd[1]}`
          let phone = JSON.stringify(phoneArr)
          let provinceCode = this.AddcityCode[0]
          let cityCode = this.AddcityCode[1]
          let userId = this.addUser
          let address = this.addRess
          // console.log('经纬度:', longitude, latitude,';','电话:', phone, '省:',provinceCode, '市:', cityCode, '管理员账号:', userId, '详细地址:', address, '营地类型:', this.addCampType)
          // let parms = {
          //   longitude,
          //   latitude,
          //   phone,
          //   provinceCode,
          //   cityCode,
          //   userId,
          //   address,
          //   name: this.addName,
          //   type: this.addCampType
          // }
          let parms = new FormData()
          parms.append('longitude',longitude)
          parms.append('latitude',latitude)
          parms.append('phone',phone)
          parms.append('provinceCode',provinceCode)
          parms.append('cityCode',cityCode)
          parms.append('userId',userId)
          parms.append('address',address)
          parms.append('name',this.addName)
          parms.append('type',this.addCampType)
          console.log(parms)
          const res = await campsiteAdd(parms)
          console.log(res)
          if (res.ResultCode === 0) {
            this.$message({
              type: 'success',
              message: res.ResultInfo
            })
            this.jwd = []
            this.addPhoneList = [
              {
                value: ''
              }
            ]
            this.$refs.map.jwd = ''
            this.$refs.map.address = ''
            this.AddcityCode = ''
            this.addUser = ''
            this.addRess = ''
            this.addName = ''
            this.addCampType = ''
            this.addShow = false
            this.getList()
          }
        }
      }
    },
    // 搜索地址
    seachMap() {
      this.$refs.map.seachMap_Map(this.addRess)
    }
  }
}
</script>

<style lang="scss" scoped>
.camp-manager {
  background: #f2f2f2;
  padding: 0 20px;
  overflow: auto;
}

.camp-search {
  background: #fff;
  margin: 20px 0;
  padding-top: 36px;
  padding-left: 36px;
  //height: 116px;
  box-sizing: border-box;

}

.card-main {
  background: #fff;
  padding: 30px 23px;
  box-sizing: border-box;

  .card-list {
    margin-top: 33px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .item {
      margin-right: 88px;
      margin-bottom: 50px;
    }
  }
  //.detail_box{
  //  background: #00a0e9;
  //  .el-dialog__header{
  //    .detail_box_title{
  //      background: #000;
  //    }
  //  }
  //}
}
//.detail_box_title{
//  height: 50px;
//  background: #20222A;
//  line-height: 50px;
//  color: #fff;
//  padding: 0 37px;
//}
</style>
