import service from './request'

const qs = require('qs')

// 营地管理
export function fetchList(data) {
    return service({
        url: "/yylb/front/campsite/list",
        method: "post",
        data
    })
}

// 办理入住
export function submitCheckin(data) {
    return service({
        url: '/yylb/check_roomMgr/Add',
        method: 'post',
        data
    })
}

//办理离店
export function submitCheckout(data) {
    return service({
        url: '/yylb/check_roomMgr/update',
        method: 'post',
        data
    })
}

// 获取入住房间类型
export function fetchRoomType(data) {
    return service({
        url: '/yylb/calendar_roomMgr/listAll',
        method: 'get',
        data
    })
}

// 获取入住房间列表
export function fetchRoomList(data) {
    let params = qs.stringify(data)
    return service({
        url: '/yylb/calendar_roomMgr/roomStatus?' + params,
        method: 'get'
    })
}

// 获取订单详情
export function fetcheOrderDetail(data) {
    // let params=qs.stringify(data)
    return service({
        url: '/yylb/orderList/' +data,
        method: 'get'
    })
}

// 营地删除
export function removeItem(data) {
    return service({
        url: `/yylb/front/campsite/remove?pid=${data}`,
        method: 'POST'
    })
}

// [上架|下架]
export function campsiteUp(data) {
    return service({
        url: '/yylb/front/campsite/up',
        method: 'POST',
        data
    })
}

// 重置密码
export function resetPassword(data) {
    return service({
        url: `/yylb/front/campsite/resetPassword?userId=${data}`,
        method: 'POST'
    })
}

// 获取省市区
export function areaList(data) {
    return service({
        url: `/yylb/areaMgr/queryList`,
        method: 'POST',
        data
    })
}

// 营地详情
export function campsiteDeatil(pid) {
    return service({
        url: `/yylb/campsiteMgr/${pid}`,
        method: 'GET'
    })
}

// 新增营地
export function campsiteAdd(data) {
    return service({
        url: '/yylb/front/campsite/add',
        method: 'POST',
        data
    })
}
