<template>
  <div style="margin-left: -70px;border: 1px solid #000;margin-top: 10px;">
    <div style="margin-bottom: 10px;margin-top: 10px;display: flex;justify-content: space-between;">
      <div style="position:relative;padding-left: 10px;">
        <span>按地址搜索</span>
        <!--<el-input id="keyword" name="keyword" v-model="address" style="width: 400px;"></el-input>-->
        <input id="keyword" v-model="address" style="width: 500px;" autocomplete="off" type="textarea">
        <!--<el-button type="primary" style="margin-left: 10px;" @click="enter">搜索地址</el-button>-->
        <div id="tipbox" style="border: 1px solid #000;position: absolute;top:60px;z-index: 1024;background: #fff;"></div>
      </div>
      <!--<div>-->
      <!--  <span>点击获取经纬度</span>-->
      <!--  <el-input v-model="jwd" style="width: 200px;" disabled></el-input>-->
      <!--</div>-->
    </div>
    <div id="container"></div>
  </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  data() {
    return {
      map: null,
      geoCoder: null,
      AMap: null,
      markersPosition: [],
      markers: [],
      jwd: '',
      address: '',
      autoCompleteComponent: null,
      placeSearchComponent: null,
      circle: null,
      markersPosition2: []
    }
  },
  mounted() {
    this.initMap()
  },
  watch: {
    address(newV,old) {
      console.log(old, '旧址')
      console.log(newV, '新值')
    }
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: '98a14dc99a15cc533b911e235c44af20',
        plugins: ['AMap.Geocoder','AMap.Autocomplete','AMap.PlaceSearch']
      }).then((AMap) => {
        this.map = new AMap.Map("container",{  //设置地图容器id
          viewMode:"3D",    //是否为3D地图模式
          zoom: 15,           //初始化地图级别
          center:[120.14,30.25], //初始化地图中心点位置
          keyboardEnable: false
        });
        this.AMap = AMap
        this.geoCoder = new AMap.Geocoder()
        // 搜索
        let that = this
        AMap.plugin(['AMap.Autocomplete','AMap.PlaceSearch'], function () {
          let autoOptions = {
            input: 'keyword',//使用联想输入的input的id
            datatype: 'all',
            output: 'tipbox'
          };
          let autocomplete = new AMap.Autocomplete(autoOptions)
          let placeSearch = new AMap.PlaceSearch({
            city: '全国',
            map: that.map
          })
          console.log(placeSearch, 878)
          AMap.event.addListener(autocomplete, 'select', (e) => {
            console.log('监听事件', e);
            that.seach(e)
          })
        })
        this.handlerMapClick()
      }).catch(e=>{
        console.log(e, '<<<<<<<<<地图创建失败');
      })
    },
    // 搜索
    seach(e) {
      console.log(e, '搜索事件<<<<<<')
      this.markersPosition = [e.poi.location.lng, e.poi.location.lat]
      this.jwd = this.markersPosition.toString()
      this.$emit('la', this.markersPosition)
      this.map.setCenter(this.markersPosition)
      this.removeMarker()
      this.setMapMarker()
      this.removeClir()
      this.setClir()
      let that = this
      this.geoCoder.getAddress(this.markersPosition, function (status,result){
        if (status === 'complete' && result.regeocode) {
          that.address = result.regeocode.formattedAddress
          // that.$emit('address', that.address)
        }
      })
    },
    handlerMapClick() {
      this.map.on('click',(e) => {
        // 点击坐标
        this.markersPosition = [e.lnglat.lng, e.lnglat.lat]
        this.jwd = this.markersPosition.toString()
        this.$emit('la', this.markersPosition)
        this.removeMarker()
        this.setMapMarker()
        let that = this
        this.removeClir()
        this.setClir()
        this.geoCoder.getAddress(this.markersPosition, function (status,result){
          if (status === 'complete' && result.regeocode) {
            that.address = result.regeocode.formattedAddress
            that.$emit('address', that.address)
          }
        })
      })
    },
    setMapMarker() {
      let marker = new this.AMap.Marker({
        map: this.map,
        position: this.markersPosition
      })
      this.markers.push(marker)
    },
    setMapMarker2() {
      let marker = new this.AMap.Marker({
        map: this.map,
        position: this.markersPosition2
      })
      this.markers.push(marker)
    },
    removeMarker() {
      if (this.markers) {
        this.map.remove(this.markers)
      }
    },
    // 设置半径
    setClir() {
      let that = this
      that.circle = new this.AMap.Circle({
        center: that.markersPosition,
        radius: 500,
        borderWeight: 1,
        strokeColor: "#3070FF", //边框颜色
        strokeOpacity: 1, //边框透明度
        strokeWeight: 1, //边框粗细
        fillOpacity: 0.05, //填充色透明度
        strokeStyle: 'solid', //边框实线solid虚线dashed
        strokeDasharray: [10, 10],
        fillColor: '#3096FF', //填充色
        zIndex: 50
      })
      that.circle.setMap(this.map)
      that.circle.on('click', that.circleClick)
    },
    circleClick(e) {
      let that = this
      console.log(e, '点击了原')
      let lng = e.lnglat.getLng()
      let lnt = e.lnglat.getLat()
      this.markersPosition2 = [lng, lnt]
      console.log(this.markersPosition2)
      this.geoCoder.getAddress(this.markersPosition2, function (status,result){
        console.log(status)
        if (status === 'complete' && result.regeocode) {
          that.address = result.regeocode.formattedAddress
          // that.$emit('address', that.address)
        }
      })
      this.$emit('la', this.markersPosition2)
      this.removeMarker()
      this.setMapMarker2()
    },
    // 删除半径
    removeClir() {
      if (this.circle) {
        this.map.remove(this.circle)
      }
    },
    // 回车事件
    enter() {
      // 根据地址定位
      let that = this
      this.geoCoder.getLocation(that.address, function (status, result) {
        if (status === 'complete' && result.info === 'OK') {
          console.log(result)
          that.address = result.geocodes[0].formattedAddress
          that.$emit('address', that.address)
          let { lng, lat } = result.geocodes[0].location
          that.markersPosition = [lng, lat]
          console.log(that.markersPosition)
          that.map.setCenter(that.markersPosition)
          that.removeClir()
          that.setClir()
          that.removeMarker()
          that.setMapMarker()
        }
      })
    },
    // 搜索地址
    seachMap_Map(keyword) {
      console.log(keyword, '8888<<<<<<<<<<<<<<')
      let that = this
      this.geoCoder.getLocation(keyword, function (status, result) {
        if (status === 'complete' && result.info === 'OK') {
          that.address = result.geocodes[0].formattedAddress
          let { lng,lat } = result.geocodes[0].location
          that.markersPosition = [lng, lat]
          that.map.setCenter(that.markersPosition)
          that.removeClir()
          that.setClir()
          that.removeMarker()
          that.setMapMarker()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#container{
  padding: 0;
  margin: 0;
  width: 100%;
  height: 600px;
}
#keyword{
  //background-color: #F5F7FA;
  border-color: #E4E7ED;
  //color: #C0C4CC;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  border: none;
  border: 1px solid #C0C4CC;
  border-radius: 4px;
}
</style>
