<template>
  <div class="card-box">
    <div class="card-hd">
      <div class="hd-img">
        <img v-if="itemData.homePage !== null && itemData.homePage !== undefined && itemData.homePage !== ''" :src="'http://yy-camping-hz.oss-cn-hangzhou.aliyuncs.com'+itemData.homePage" style="width: 100%;height: 100%;border-radius: 50%;">
      </div>
      <div class="hd-name">
        <h2 slot="reference">{{ itemData.name }}</h2>
        <div class="time">
          <div class="t-left">创建时间：{{ itemData.createTime | formatDate }}</div>
          <div class="t-switch">
            <!--<cSwitch v-model="itemData.isshow" active-value="1" inactive-value="0" active-txt="上架" inactive-txt="下架" acitveColor="#348DF0"-->
            <!--         inactiveColor="#333333" @input="Schange"></cSwitch>-->
            <el-switch
                v-model="itemData.isshow"
                active-value="1"
                inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="SwitchC"
            >
            </el-switch>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <p>地址：<el-tooltip class="item" effect="dark" :content="itemData.address" placement="top-start"><span>{{ itemData.address }}</span></el-tooltip></p>
      <!--<p>电话：{{ itemData.phone }}</p>-->
      <p>电话:<span v-for="(item, index) in itemData.phone" :key="index" style="margin-left: 5px;">{{ item }}</span>
      </p>
    </div>
    <div class="card-bottom">
      <div class="card-left">
        <el-link type="primary" class="link-txt" @click="goDetail">店铺详情</el-link>
        <el-link type="primary" class="link-txt" @click="go">进入后台</el-link>
        <el-link type="primary" class="link-txt" @click="CZ">重置密码</el-link>
      </div>
      <div class="card-del">
        <i class="el-icon-delete" style="cursor:pointer;" @click="deleteItem"></i>
      </div>
    </div>
  </div>

</template>

<script>
// import cSwitch from './switch'
import dayjs from 'dayjs'

export default {
  props: {
    itemData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  filters: {
    formatDate(val) {
      if (val) {
        let date = dayjs(val).format('YYYY-MM-DD')
        return date
      }
    }
  },
  // components: {
  //   cSwitch
  // },
  data() {
    return {
      switchValue: false
    }
  },
  methods: {
    CZ() {
      this.$emit('resetPswd', this.itemData.userId)
    },
    deleteItem() {
      this.$emit('remove', this.itemData.pid)
    },
    SwitchC(e) {
      this.$emit('up', this.itemData.pid, e)
    },
    // 进入后台
    go() {
      // window.open('http://testapi.yunyouzhihui.com/start/index.html#/user/loginLbMerchant', '_black')
      // window.open('http://yyadmin.yunyouzhihui.com/start/index.html#/user/loginLbMerchant', '_black')
      // window.open(`${this.$BASEURL}/start/index.html#/user/loginLbMerchant`, '_black')
      const data = {
        userId: this.itemData.userId,
        links: this.itemData.links
      }
      window.parent.postMessage(data, '*')
    },
    // 店铺详情
    goDetail() {
      // window.open(`http://testapi.yunyouzhihui.com/start/index.html#/yy/lb_campsite/view/pid=${this.itemData.pid}`,'_black')
      this.$emit('detail', this.itemData.pid)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-box {
  width: 450px;
  height: 262px;
  background: #F3F9FF;
  border: 1px solid #348DF0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 24px;
  box-sizing: border-box;

  .card-hd {
    display: flex;
    justify-content: flex-start;
    //padding: 0 20px;
    box-sizing: border-box;
    padding-left: 20px;

    .hd-img {
      display: block;
      width: 66px;
      height: 66px;
      background: #00A0E9;
      border-radius: 50%;
      margin-right: 17px;
    }

    .hd-name {
      flex: 1;
      padding: 0 13px;

      h2 {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 36px;
      }

      .time {
        display: flex;
        justify-content: space-between;

        .t-left {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
          line-height: 36px;
        }

        .t-right {
          .inactive {
            color: #00A0E9;
          }
        }
      }
    }
  }

  .card-body {
    padding: 0 20px;

    p {
      line-height: 34px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .card-bottom {
    height: 61px;
    border-top: 1px solid rgba(47, 105, 169, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 13px;

    .card-left {
      .link-txt {
        margin: 0 10px;
      }
    }
  }
}
</style>
